import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { API, HTTP_STATUS } from '../../constans/http';
import http from '../../services/http.service';
import classes from './index.module.scss';
import Button from '../../components/UI/Button/Button';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import Label from '../../components/UI/Label/Label';
import InputPassword from '../../components/UI/InputPassword/InputPassword';
import { useDisplayServerError } from '../../hooks/useDisplayServerError';
import {
  parseSearchParams,
  replaceStringSpacesByPlus,
} from '../../helpers/helper-methods';
import { RESET_PWD } from '../../constans/form-fields';
import {
  PASSWORD_INVALID_TIP,
  // PASSWORD_REQUIRED,
  PASSWORD_VALID,
  CONFIRM_PASSWORD_DOESNT_MATCH,
  CONFIRM_PASSWORD_REQUIRED,
} from '../../constans/error-messages';
// import {
//   REGEXP_NOSPACES,
//   REGEXP_ONE_LETTER,
//   REGEXP_ONE_CAPITAL,
//   REGEXP_ONE_DIGIT,
//   PWD_MIN,
//   PWD_MAX,
// } from '../../constans/validation';
import { BLOCKED_USER_LINK, VERIFICATION_LINK } from '../../constans/links';
import { passwordValidationSchema } from '../../constans/validation-schemas';

const initialValues = {
  password: RESET_PWD.password.initialValue,
  confirmPassword: RESET_PWD.confirmPassword.initialValue,
};

const validationSchema = Yup.object({
  password: passwordValidationSchema,
  confirmPassword: Yup.string().required(CONFIRM_PASSWORD_REQUIRED).oneOf(
    [Yup.ref('password'), null],
    CONFIRM_PASSWORD_DOESNT_MATCH,
  ),
});

const Restoration = ({ location }) => {
  const [serverError, setServerError] = useDisplayServerError();
  const params = parseSearchParams(location);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (params.token) {
      setToken(replaceStringSpacesByPlus(params.token));
    }
  }, [params.token]);

  const formSubmitHandler = ({ password }) => {
    http
      .put(API.PASSWORD_RESET, { password, token })
      .then(() => {
        setServerError('');
        navigate('/signin/');
      })
      .catch(({ status, message }) => {
        if ([HTTP_STATUS.BLOCKED_USER].includes(status)) {
          navigate(BLOCKED_USER_LINK);
        } else if ([HTTP_STATUS.UNVERIFIED].includes(status)) {
          navigate(VERIFICATION_LINK, { state: { pwdRedirect: true } });
        } else {
          setServerError(message);
        }
        // if ([HTTP_STATUS.BAD_REQUEST].includes(err.response.status)) {
        //   setServerError(err.response.data.error.message);
        // } else {
        //   navigate('/verification/', { state: { pwdExpired: true } });
        // }
      });
  };

  return (
    <div className="blue-grad-bg center-vertical-page-wrapper">
      <div
        className={`${classes.container} center-content-vertical-mobile container-bordered auth-wrapper`}
      >
        <div className="auth-title block-title">
          Reset Password
          {serverError && <div className="titleError">{serverError}</div>}
        </div>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col flex-v-end">
              <InputWrapper containerClasses={classes.inputWrapper}>
                <Label>Password</Label>
                <div>
                  <InputPassword
                    name={RESET_PWD.password.name}
                    placeholder={RESET_PWD.password.placeHolder}
                  />
                  <div className="password-tip">
                    {errors.password ? (
                      PASSWORD_INVALID_TIP
                    ) : (
                      <span className="success-color">{PASSWORD_VALID}</span>
                    )}
                  </div>
                </div>
              </InputWrapper>
              <InputWrapper containerClasses={classes.inputWrapper}>
                <Label>Confirm Password</Label>
                <div>
                  <InputPassword
                    // hideError={false}
                    name={RESET_PWD.confirmPassword.name}
                    placeholder={RESET_PWD.confirmPassword.placeHolder}
                  />
                  {errors?.confirmPassword && touched?.confirmPassword ? (
                    <div className={`${classes.inputError} error-input-msg`}>
                      {errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </InputWrapper>
              <Button
                classes={`${classes.submitBtn} btn btn_block btn-uppercased btn_common btn_auth t-600`}
                type="submit"
              >
                Save password
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Restoration;
